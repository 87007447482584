import React, { useState } from 'react'

// Third party
import styled from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'

// Elements
import ParseContent from 'components/shared/ParseContent'
import BlockPrimary from 'components/elements/Blocks/BlockPrimary'

// Images
import ArrowDown from 'img/arrowdown.inline.svg'
import Close from 'img/close.inline.svg'

const Section = styled.section`
  position: relative;
  z-index: 2;
`

const Content = styled(ParseContent)`
  font-family: ${({ theme }) => theme.font.family.primary};
  @media (min-width: 768px) {
    padding-right: 10rem;
  }

  & img {
    object-fit: contain !important;
  }
`

interface AccordionProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Accordion
}

const Accordion: React.FC<AccordionProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState<number | null>(0)

  const descriptionVariants = {
    open: {
      opacity: 1,
      height: 'auto',
    },
    collapsed: {
      opacity: 0,
      height: 0,
    },
  }
  return (
    <Section>
      <div className="container py-5">
        <div className="row">
          <div className="col-12">
            <ParseContent content={fields.description} className="mb-5" />
            {fields.accordion?.map((item, index: number) => {
              const open: boolean = currentIndex === index
              return (
                <BlockPrimary className="p-0">
                  <div
                    className="d-flex justify-content-between align-items-center p-4"
                    onClick={() =>
                      open ? setCurrentIndex(null) : setCurrentIndex(index)
                    }
                    role="button"
                    onKeyDown={() => setCurrentIndex(index)}
                    tabIndex={-1}
                  >
                    <ParseContent content={item?.title} />
                    {!open && <ArrowDown />}
                    {open && <Close />}
                  </div>
                  <AnimatePresence>
                    {open && (
                      <motion.div
                        initial="collapsed"
                        animate="open"
                        exit="collapsed"
                        variants={descriptionVariants}
                        transition={{
                          duration: 0.8,
                          ease: [0.04, 0.62, 0.23, 0.98],
                        }}
                      >
                        <Content content={item?.description} className="p-4" />
                      </motion.div>
                    )}
                  </AnimatePresence>
                </BlockPrimary>
              )
            })}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Accordion
